export type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export interface LogEntry {
  level: LogLevel
  space: string
  message: string
  data?: Object
}

export type LogHandler = (entry: LogEntry) => void

export interface LogOptions {
  handler?: LogHandler
  space: string
}

export const SignInEvent = 'SignIn' as const
export const RegisterEvent = 'Register' as const
export const UpdatePasswordEvent = 'UpdatePassword' as const
export const SendResetPasswordEmailEvent = 'SendResetPasswordEmail' as const
export const ResetPasswordEvent = 'ResetPassword' as const
export const SubmitArticleEvent = 'SubmitArticle' as const
export const SubmitProjectEvent = 'SubmitProject' as const
export const SubmitEventEvent = 'SubmitEvent' as const
export const SubmitCommunityEvent = 'SubmitCommunity' as const
export const CreatePostEvent = 'CreatePost' as const
export const CreateCommentEvent = 'CreateComment' as const
export const ReplyCommentEvent = 'ReplyComment' as const
export const UpdateSettingsEvent = 'UpdateSettings' as const
export const UpvotePostEvent = 'UpvotePost' as const
export const LinkWalletEvent = 'LinkWallet' as const
export const UnlinkWalletEvent = 'UnlinkWallet' as const
export const BlockUserEvent = 'BlockUser' as const
export const UnblockUserEvent = 'UnblockUser' as const
export const EditOnboardingPostEvent = 'EditOnboardingPost' as const

interface EventMessage {
  success: boolean
  code: number
  message: string
}

interface LoggingUser extends EventMessage {
  email?: string
}

interface PostMethodInfo extends EventMessage {
  isEdit?: boolean
  id?: string
  data: Object
}

export type EventOptions = {
  [SignInEvent]: LoggingUser
  [RegisterEvent]: LoggingUser
  [SendResetPasswordEmailEvent]: LoggingUser
  [UpdatePasswordEvent]: LoggingUser
  [ResetPasswordEvent]: LoggingUser
  [SubmitArticleEvent]: PostMethodInfo
  [SubmitProjectEvent]: PostMethodInfo
  [SubmitEventEvent]: PostMethodInfo
  [SubmitCommunityEvent]: PostMethodInfo
  [CreatePostEvent]: PostMethodInfo
  [CreateCommentEvent]: PostMethodInfo
  [ReplyCommentEvent]: PostMethodInfo
  [UpdateSettingsEvent]: PostMethodInfo
  [UpvotePostEvent]: PostMethodInfo
  [LinkWalletEvent]: PostMethodInfo
  [UnlinkWalletEvent]: PostMethodInfo
  [BlockUserEvent]: PostMethodInfo
  [UnblockUserEvent]: PostMethodInfo
  [EditOnboardingPostEvent]: PostMethodInfo
}

export type MonitorEvent = keyof EventOptions

export type MonitorHandler = <MonitorEventOptions extends EventOptions[MonitorEvent]>(
  eventName: MonitorEvent | string,
  eventOptions: MonitorEventOptions | Record<string, string>
) => void

export interface MonitorContext {
  // critical events are usually tracked more often
  criticalEvent: <MonitorEvents extends EventOptions[MonitorEvent]>(
    eventName: MonitorEvent | string,
    eventOptions: MonitorEvents
  ) => void
  // custom events are tracked using RUM which is less often
  customAction: <MonitorEvents extends EventOptions[MonitorEvent]>(
    eventName: MonitorEvent | string,
    eventOptions: MonitorEvents
  ) => void
  // set custom attribute to the user tracked using RUM, so it is possible later to filter users by specific attribute name
  setCustomAttribute: (attributeName: string, attributeValue: string | number) => void
}
